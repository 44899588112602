import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter } from "react-router-dom";
import { createStore } from "redux";
import jwt from "jsonwebtoken";
import { Provider } from "react-redux";

import "./index.css";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import reducer from "./store/reducer";
import * as actionTypes from "./store/actions";

const store = createStore(reducer);

if (localStorage.jwtToken) {
  store.dispatch({
    type: actionTypes.SET_USER,
    user: jwt.decode(localStorage.jwtToken).user,
  });
}

if (localStorage.lang) {
  store.dispatch({
    type: actionTypes.SET_LANG,
    lang: localStorage.lang,
  });
}

ReactDOM.render(
  <Provider store={store}>
    <BrowserRouter>
      <App />
    </BrowserRouter>
  </Provider>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
