import React from "react";
import { Route, Switch } from "react-router-dom";
import { useSelector } from "react-redux";
import Loadable from "react-loadable";
import * as actionTypes from "./store/actions";

function Loading({ error }) {
  if (error) {
    return "Oh nooess!";
  } else {
    return <h3 style={{ display: "none" }}>Loading...</h3>;
  }
}

const App = () => {
  const AppBar = Loadable({
    loader: () => import("./components/AppBar/AppBar"),
    loading: Loading,
  });
  const Footer = Loadable({
    loader: () => import("./components/Footer/Footer"),
    loading: Loading,
  });
  const Home = Loadable({
    loader: () => import("./components/Home/Home"),
    loading: Loading,
  });
  const AboutUs = Loadable({
    loader: () => import("./components/AboutUs/AboutUs"),
    loading: Loading,
  });
  const Services = Loadable({
    loader: () => import("./components/Services/Services"),
    loading: Loading,
  });
  const Production = Loadable({
    loader: () => import("./components/Production/Production"),
    loading: Loading,
  });
  const MediaContentAnalysis = Loadable({
    loader: () =>
      import("./components/MediaContentAnalysis/MediaContentAnalysis"),
    loading: Loading,
  });
  const Translation = Loadable({
    loader: () => import("./components/Translation/Translation"),
    loading: Loading,
  });
  const Monitoring = Loadable({
    loader: () => import("./components/Monitoring/Monitoring"),
    loading: Loading,
  });
  const ExecutiveNewsletter = Loadable({
    loader: () =>
      import("./components/ExecutiveNewsletter/ExecutiveNewsletter"),
    loading: Loading,
  });
  const EventOrg = Loadable({
    loader: () => import("./components/EventOrg/EventOrg"),
    loading: Loading,
  });
  const SocialNetwork = Loadable({
    loader: () => import("./components/SocialNetwork/SocialNetwork"),
    loading: Loading,
  });
  const Research = Loadable({
    loader: () => import("./components/Research/Research"),
    loading: Loading,
  });
  const RentingPlace = Loadable({
    loader: () => import("./components/RentingPlace/RentingPlace"),
    loading: Loading,
  });
  const ContactUs = Loadable({
    loader: () => import("./components/ContactUs/ContactUs"),
    loading: Loading,
  });
  const Post = Loadable({
    loader: () => import("./components/Post/Post"),
    loading: Loading,
  });
  const Login = Loadable({
    loader: () => import("./containers/Login/Login"),
    loading: Loading,
  });
  const Editor = Loadable({
    loader: () => import("./containers/Editor/Editor"),
    loading: Loading,
  });
  const Alert = Loadable({
    loader: () => import("./containers/Alert/Alert"),
    loading: Loading,
  });
  const Blog = Loadable({
    loader: () => import("./containers/Blog/Blog"),
    loading: Loading,
  });
  const ApplicationLogin = Loadable({
    loader: () => import("./containers/ApplicationLogin/ApplicationLogin"),
    loading: Loading,
  });
  const Search = Loadable({
    loader: () => import("./containers/SearchResult/SearchResult"),
    loading: Loading,
  });
  const Buzz = Loadable({
    loader: () => import("./components/Buzz/Buzz"),
    loading: Loading,
  });
  const TermsAndConditions = Loadable({
    loader: () => import("./containers/TermsAndConditions/TermsAndConditions"),
    loading: Loading,
  });
  return (
    <div>
      <AppBar />
      <Alert />
      <Switch>
        <Route exact path="/" component={Home} />
        <Route exact path="/aboutus" component={AboutUs} />
        <Route exact path="/services" component={Services} />
        <Route exact path="/app" component={ApplicationLogin} />
        <Route exact path="/service/production" component={Production} />
        <Route
          exact
          path="/service/analysis"
          component={MediaContentAnalysis}
        />
        <Route
          exact
          path="/service/analysis"
          component={MediaContentAnalysis}
        />
        <Route exact path="/service/translation" component={Translation} />
        <Route exact path="/service/monitoring" component={Monitoring} />
        <Route
          exact
          path="/service/exenewsletter"
          component={ExecutiveNewsletter}
        />
        <Route exact path="/service/eventorg" component={EventOrg} />
        <Route exact path="/service/socialnetwork" component={SocialNetwork} />
        <Route exact path="/service/research" component={Research} />
        <Route exact path="/service/rentplace" component={RentingPlace} />
        <Route exact path="/buzz" component={Buzz} />
        <Route exact path="/contactus" component={ContactUs} />
        <Route exact path="/posts/:id" component={Post} />
        <Route exact path="/login" component={Login} />
        <Route exact path="/editor" component={Editor} />
        <Route exact path="/blog" component={Blog} />
        <Route exact path="/blog" component={Blog} />
        <Route exact path="/search" component={Search} />
        <Route exact path="/terms-and-conditions" component={TermsAndConditions} />
      </Switch>
      <Footer />
    </div>
  );
};

export default App;
