import * as actionTypes from "./actions";

const initialState = {
  user: null,
  alertMessage: "",
  alertErrorMessage: "",
  lang: "sr",
  isMobileVideoOpen: false,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SET_USER:
      return {
        ...state,
        user: action.user,
      };
    case actionTypes.SET_ALERT_MESSAGE:
      return {
        ...state,
        alertMessage: action.message,
      };
    case actionTypes.SET_ALERT_ERROR:
      return {
        ...state,
        alertErrorMessage: action.error,
      };
    case actionTypes.SET_LANG:
      return { ...state, lang: action.lang };
    case actionTypes.TOGGLE_MOBILE_VIDEO:
      console.log(!state.isMobileVideoOpen);
      return { ...state, isMobileVideoOpen: !state.isMobileVideoOpen };
    default:
      return {
        ...state,
      };
  }
};

export default reducer;
